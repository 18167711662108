@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	src: url(dist/fonts/Source_Sans_Pro-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Open Sans';
	src: url(dist/fonts/OpenSans-Bold.eot);
	src: url(dist/fonts/OpenSans-Bold.eot?#iefix) format('embedded-opentype'),
			 url(dist/fonts/OpenSans-Bold.woff2) format('woff2'),
			 url(dist/fonts/OpenSans-Bold.woff) format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url(dist/fonts/OpenSans-Italic.eot);
	src: url(dist/fonts/OpenSans-Italic.eot?#iefix) format('embedded-opentype'),
			url(dist/fonts/OpenSans-Italic.woff2) format('woff2'),
			url(dist/fonts/OpenSans-Italic.woff) format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url(dist/fonts/OpenSans-Regular.eot);
	src: url(dist/fonts/OpenSans-Regular.eot?#iefix) format('embedded-opentype'),
			url(dist/fonts/OpenSans-Regular.woff2) format('woff2'),
			url(dist/fonts/OpenSans-Regular.woff) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url(dist/fonts/OpenSans-BoldItalic.eot);
	src: url(dist/fonts/OpenSans-BoldItalic.eot?#iefix) format('embedded-opentype'),
			url(dist/fonts/OpenSans-BoldItalic.woff2) format('woff2'),
			url(dist/fonts/OpenSans-BoldItalic.woff) format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

